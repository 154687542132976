<template>
    <v-menu
      ref="menu"
      v-model="isVisible"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="pickerDate"
          readonly
          v-bind="attrs"
          v-on="on"
          hide-details
          prepend-icon="event"
          style="width: 140px;"
        ></v-text-field>
      </template>
      <v-date-picker v-model="pickerDate" no-title scrollable :max="maxDate" :min="minDate" color="#b00016" @change=onChangeHandler>
      </v-date-picker>
    </v-menu>
</template>

<script>
  export default {
    name: 'Naverreportlist',
    props: ['date', 'minDate','maxDate'],
    data() {
        return {
            pickerDate : this.date,
            isVisible: false,
        }
    },
    computed: {
    },
    methods: {
        onChangeHandler(v){
            this.$emit('onDateChangeHandler', v);
        }
    },
    watch : {
        date(){
            this.pickerDate = this.date;
        }
    }
  };
</script>

<style scoped>
.accent{
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}

.accent--text{
    color: var(--primary-color) !important;
    caret-color: var(--primary-color) !important;
}

.v-text-field{
  padding-top: 0 !important;
  margin-top: 0 !important;
}
</style>
