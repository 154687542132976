<template>
  <v-row
    v-if="isShowGuide"
    style="background-color: #bd1328; display: flex; align-items: center"
    :style="
      $vuetify.breakpoint.mdAndUp
        ? 'padding: 16px 34px'
        : 'padding: 16px 20px; margin-top : 24px'
    "
  >
    <v-icon color="white" size="24">mdi-alert-circle-outline</v-icon>
    <p
      style="
        color: white;
        white-space: pre;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
      "
    >
      {{
        $vuetify.breakpoint.mdAndUp
          ? '광고 진행을 위해 결제 수단을 등록해주세요.'
          : '카드 등록을 하시면\n 광고 진행이 더욱 수월합니다.'
      }}
    </p>
    <div
      @click="movePayPlan"
      style="
        cursor: pointer;
        margin-left: auto;
        display: flex;
        border: 2px solid white;
        justify-content: center;
        align-items: center;
        padding: 4px 8px 4px 20px;
        border-radius: 20px;
      "
    >
      <span
        style="
          color: white;
          font-weight: 700;
          font-size: 13px;
          line-height: 20px;
        "
        >바로가기</span
      >
      <v-icon color="white" size="24">mdi-chevron-right</v-icon>
    </div>
  </v-row>
</template>

<script>
import userMixin from '@/components/shared/userMixin';
import companyMixin from '@/components/shared/companyMixin';

export default {
  name: 'CardRegistrationReminder',
  mixins: [userMixin, companyMixin],
  data() {
    return {
      isShowGuide: false,
    };
  },
  watch: {
    user(value) {
      this.getInitData(value);
    }
  },
  methods: {
    movePayPlan() {
      this.$router.push('/company/pay');
    },
    async getInitData(user) {
      const {
        data: { total = 0 },
      } = await this.axios.get('/company/user/card?userId=' + user.id);
      this.isShowGuide = !total;
    }
  },
  async created() {
    if (this.user) {
      this.getInitData(this.user);
    }
  },
};
</script>

<style scoped lang="scss"></style>
