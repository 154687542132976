<script>
  import {Bar} from 'vue-chartjs'

  import companyMixin from '../../shared/companyMixin'

  export default {
    name: 'ContractChart',
    extends: Bar,
    mixins: [companyMixin],
    props: ['datas'],
    data() {
      return {
        contracts: []
      }
    },
    watch: {
      datas() {
        this.displayChart();
      }
    },
    methods: {
      displayChart() {
        this.renderChart({
          labels: this.datas.map((data) => data.label),
          datasets: [
          {
            label: '다이아',
            backgroundColor: '#C2C2C2',
            borderColor: '#C2C2C2',
            borderWidth: 1,
            data: this.datas.map((data) => data.diamond)
          },
          {
            label: '블랙',
            backgroundColor: 'rgba(84, 82, 82, 0.7)',
            borderColor: 'rgba(82, 82, 82, 0.7)',
            borderWidth: 1,
            data: this.datas.map((data) => data.black)
          },
            {
              label: '레드',
              backgroundColor: 'rgba(189, 19, 40, 0.7)',
              borderColor: 'rgba(245, 203, 198, 0.7)',
              borderWidth: 1,
              data: this.datas.map((data) => data.red)
            },
            {
              label: '옐로',
              backgroundColor: 'rgba(244, 203, 0, 0.7)',
              borderColor: 'rgba(245, 203, 198, 0.7)',
              borderWidth: 1,
              data: this.datas.map((data) => data.yellow)
            }]
        }, {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              ticks: {
                beginAtZero: true,
                maintainAspectRatio: true,
                max: 5
              }
            }]
          }
        });
      }
    },
    mounted() {
      this.displayChart();
    }
  }
</script>

<style scoped>

</style>